import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';

export default class AdminPaginatedListPagination extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        currentPage: PropTypes.number.isRequired,
        lastPage: PropTypes.number.isRequired,
        maxPagesCount: PropTypes.number,
    };
    static defaultProps = {
        maxPagesCount: 9,
    }

    onChange = (name, value) => {
        const { location, history } = this.props;
        const { pathname, search } = location;

        history.push(
            parseToQueryString(pathname, {
                ...parseQueryToObject(search),
                [name]: value,
            })
        );
    }

    getButton = (pageNumber, content, className = '') => {
        return (
            <a
                key={`page-${pageNumber}`}
                onClick={() => this.onChange('page', pageNumber)}
                className={`pagination-btn ${className}`}
            >
                {content}
            </a>
        );
    }

    getPreviousButton = () => {
        const { currentPage } = this.props;

        return currentPage > 1 ? this.getButton(currentPage - 1, <FontAwesomeIcon icon={['fa', 'chevron-left']} />, 'pagination-btn-previous') : null;
    }

    getNextButton = () => {
        const { currentPage, lastPage } = this.props;

        return currentPage < lastPage ? this.getButton(currentPage + 1, <FontAwesomeIcon icon={['fa', 'chevron-right']} />, 'pagination-btn-next') : null;
    }

    getPagesButtons = () => {
        const { currentPage, lastPage, maxPagesCount } = this.props;
        let buttons = [];

        //Caclulating pages before current
        let startPage = currentPage - 1;
        let endPage = currentPage - Math.floor(maxPagesCount / 2);

        for (let current = startPage; current > endPage; current--) {
            if (current > 0) {
                buttons.unshift(this.getButton(current, current, ''));
            }
        }
        //Current page
        buttons.push(this.getButton(currentPage, currentPage, 'pagination-btn-active'));

        //Caclulating pages after current
        startPage = currentPage + 1;
        endPage = currentPage + Math.floor(maxPagesCount / 2);
        for (let current = startPage; current < endPage; current++) {
            if (current <= lastPage) {
                buttons.push(this.getButton(current, current, ''));
            }
        }

        return buttons;
    }

    render() {
        const { getPreviousButton, getNextButton, getPagesButtons } = this;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="admin-paginated-list-pagination"
            >
                <div className="pagination-buttons">
                    {getPreviousButton()}
                    {getPagesButtons()}
                    {getNextButton()}
                </div>
            </StyledComponent>
        );
    }
}
