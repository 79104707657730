import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        height: 100%;

        .controls-group {
            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-direction: column;
        }
        .control {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: .5em;
            width: max-content; 
            
            .button {
                height: 100%;

                button {
                    height: 100%;
                    background: transparent;
                    border: none;
                    width: 7em;
                    padding: 0 1em;
                    border-left: 1px solid #CCC;
                    border-radius: 0;
                    font-weight: 400;
                    box-shadow: 0 0 5px 0px rgba(0,0,0,0.1);
                    color: #CCCCCC;

                    &:hover {
                        color: #333333;
                        background: #EEEEEE;
                    }
                }
            }
            .control-text-block {
                text-align: center;
                align-self: center;
                padding: 0 1em;
                color: #999;
            }
        }
    `;
