import { css } from '@emotion/core';

export default () =>
    css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter {
            width: 100%;
            padding: 0 0em;

            .form-element {
                &.style-version-2 {
                    .input-external-wrapper {
                        .input-internal-wrapper {
                            border: none;
                            border-bottom: 1px solid #666666;
                            border-radius: 0;

                            input {
                                height: 2.5em;
                                padding: 0 .5em;
                            }
                        }
                    }
                }
            }
        };

        &.style-version-1 {
            border-bottom: 1px solid #CCCCCC;
            border-top: 1px solid #EEEEEE;
        }

        &.style-version-2 {
            border-top: none;
            border-bottom: none;
        }
    `;
