import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import NoResult from 'Components/layout/NoResult';
import PaginatedListPagination from 'Components/layout/panel/PaginatedListPagination';
import PaginatedListFilterbar from 'Components/layout/panel/PaginatedListFilterbar';

export default class AdminPaginatedList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        onMapElement: PropTypes.func.isRequired,
        queryConfig: PropTypes.object,
        collection: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.shape({
                count: PropTypes.number.isRequired,
                currentPage: PropTypes.number.isRequired,
                hasMorePages: PropTypes.bool.isRequired,
                lastPage: PropTypes.number.isRequired,
                perPage: PropTypes.number.isRequired,
                total: PropTypes.number.isRequired,
            }),
        }),
        filters: PropTypes.array,
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        layout: PropTypes.oneOf([
            'list', 'images', 'vertical',
        ]),
        styleProps: PropTypes.object,
        visible: PropTypes.bool,
    };
    static defaultProps = {
        title: null,
        subtitle: null,
        collection: null,
        filters: [],
        queryConfig: {},
        layout: 'list',
        styleProps: {
            styleVersion: 1,
        },
    };

    render() {
        const { location, history, collection, title, subtitle, onMapElement, filters, layout, queryConfig, styleProps, visible } = this.props;

        if (!collection) {
            return (<Spinner />);
        }
        const { isLoading, isLoaded, elements, meta } = collection;
        const { styleVersion } = styleProps;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-paginated-list',
                    `layout-${layout}`,
                    `style-version-${styleVersion}`,
                )}
            >
                {Boolean(title || subtitle || filters.length > 0) && (
                    <div className="list-header">
                        {title ? <h2 className="list-title">{title}</h2> : null}
                        {subtitle ? <h2 className="list-subtitle">{subtitle}</h2> : null}
                        <PaginatedListFilterbar
                            location={location}
                            history={history}
                            filters={filters}
                            queryConfig={queryConfig}
                            styleVersion={styleVersion}
                            visible={visible}
                        />
                    </div>
                )}
                <div className="list-body">
                    {isLoading ? <Spinner /> : null}
                    {isLoaded && !isLoading && !elements.length
                        ? (<NoResult />)
                        : null
                    }
                    {elements.map(element => onMapElement(element))}
                </div>
                <footer className="list-footer">
                    {meta &&
                        <PaginatedListPagination
                            location={location}
                            history={history}
                            currentPage={meta.currentPage}
                            lastPage={meta.lastPage}
                        />
                    }
                </footer>
            </StyledComponent>
        );
    }
}
