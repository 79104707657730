import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    &.style-version-1 {
        .list-header {
            position: relative;
            z-index: 2;

            .list-title {
                font-size: 2em;
                color: #CCCCCC;
            }

            .list-subtitle {
                font-size: 1em;
                margin-bottom: 1em;
                color: #CCCCCC;
                font-weight: 300;
            }
        }
        .list-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            background: none;
            position: relative;
            z-index: 1;
            min-height: 10em;
        }
        .list-footer {
            margin-top: 1em;
            position: relative;
            z-index: 0;
        }
    }

    &.style-version-2 {
        .list-header {
            position: relative;
            z-index: 2;

            .list-title {
                font-size: 2em;
                color: #FFFFFF;
            }

            .list-subtitle {
                font-size: 1em;
                margin-top: 1em;
                color: #C5C5C5;
                font-weight: 300;
            }

            .admin-paginated-list-filterbar {
                border-top: none !important;
                border-bottom: none !important;
            }
        }
        .list-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            background: none;
            position: relative;
            z-index: 2;
            min-height: 10em;
        }
        .list-footer {
            margin-top: 1em;
            position: relative;
            z-index: 0;
        }
    }

    &.layout-images {
        .list-header {
            position: relative;
            z-index: 2;

            .list-title {
                font-size: 2em;
                color: #FFFFFF;
            }

            .list-subtitle {
                font-size: 1em;
                margin-top: 1em;
                color: #C5C5C5;
                font-weight: 300;
            }

            .admin-paginated-list-filterbar {
                border-top: none !important;
                border-bottom: none !important;
            }
        }
        
        .list-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: none;
            position: relative;
            z-index: 1;
            min-height: 10em;

        }
        
        .list-footer {
            margin-top: 1em;
            position: relative;
            z-index: 2;
        }
    }

    &.layout-vertical {
        .list-body {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
        }
    }
`;
