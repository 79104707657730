import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class MultiSelectButtonComponent extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        type: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        controls: PropTypes.array,
        styleVersion: PropTypes.object,
    };

    static defaultProps = {
        className: '',
        onClick: null,
        disabled: false,
        controls: [],
    };

    state = {
        areOptionsVisible: false,
    }

    render() {
        const { areOptionsVisible } = this.state;
        const { controls, className } = this.props;

        return (
            <StyledComponent 
                styles={require('./styles')} 
                className={classnames(
                    'multi-select-component-button', 
                    className
                )}
            >
                <div 
                    className={classnames(
                        'buttons-wrapper',
                        {
                            areOptionsVisible,
                        }
                    )}
                    onClick={() => this.setState(prevState => ({ areOptionsVisible: !prevState.areOptionsVisible }))}
                    onMouseLeave={() => this.setState({ areOptionsVisible: false })}
                >
                    <Button
                        layout='fullWidth'
                        className='label'
                        style={controls[0].style || 'faPrimary'}
                    >
                        Opcje
                    </Button>
                    <div 
                        className="controls-wrapper"
                    >
                        {controls.map(control => {
                            if (control.visible != false) {    
                                return (
                                    <div className="visible-button">
                                        <Button
                                            key={control.label}
                                            type={control.type}
                                            onClick={control.disabled ? null : control.onClick}
                                            disabled={control.disabled}
                                            layout='fullWidth'
                                            style={control.style || undefined}
                                        >
                                            <span className="button-content">{control.label}</span>
                                        </Button>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}