import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .buttons-wrapper {
            position: relative;

            .label {
                z-index: 0;
            }

            .component-button {
                .button {

                    &.style-faPrimary {
                        padding: 0.5em 1.5em;
                    }
                }
            }

            .controls-wrapper {
                position: absolute;
                display: none;
                width: 100%;
                z-index: 1;
            }
            
            &.areOptionsVisible {
                .controls-wrapper {
                    display: block;
                }
            }
        }
    `;
