import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: .3em;
        padding: 0 3em;

        .pagination-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-radius: .3em;
            overflow: hidden;

            .pagination-btn {
                width: 2em;
                height: 2.5em;
                text-align: center;
                line-height: 2.5em;
                font-weight: 600;
                color: #666666;
                border: 1px solid #F2F2EA;
                transition: .2s background-color ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: #F2F2EA;
                }

                &.pagination-btn-active {
                    background-color: #F2F2EA;
                    color: #b8afaf;
                    text-decoration: underline;
                }
            }
        }
    `;
